document.addEventListener('turbo:load', function() {
    document.querySelectorAll('.custom-dropdown-toggle').forEach(function(dropdownButton) {
        // Bootstrap dropdown events can be listened to on the parent .dropdown element
        const dropdown = dropdownButton.closest('.dropdown');
        if (dropdown) {
            dropdown.addEventListener('show.bs.dropdown', function() {
                dropdownButton.querySelector('.angle-up-icon').classList.remove('d-none');
                dropdownButton.querySelector('.angle-down-icon').classList.add('d-none');

                const dropdownMenu = dropdown.querySelector('.dropdown-menu');
                const dropdownButtonRect = dropdownButton.getBoundingClientRect();
                const dropdownTop = dropdownButtonRect.bottom;
                const modalFooter = dropdown.closest('.modal') ? dropdown.closest('.modal').querySelector('.modal-footer') : null;
                const modalFooterHeight = modalFooter ? modalFooter.offsetHeight : 0;
                const dropdownHeight = window.innerHeight - dropdownTop - modalFooterHeight - 5; // Calculate the remaining height

                if (dropdownMenu) {
                    Object.assign(dropdownMenu.style, {
                        position: 'fixed',
                        width: '100vw',
                        height: `${dropdownHeight}px`, // Set height dynamically
                        left: '0',
                        top: `${dropdownTop}px`,
                        transform: 'none',
                        overflowY: 'auto' // Add scroll if content is larger than the menu
                    });
                }
            });

            dropdown.addEventListener('hide.bs.dropdown', function() {
                dropdownButton.querySelector('.angle-down-icon').classList.remove('d-none');
                dropdownButton.querySelector('.angle-up-icon').classList.add('d-none');
            });
        }
    });

    document.querySelectorAll('.dropdown-menu').forEach(function(dropdownMenu) {
        dropdownMenu.addEventListener('click', function(event) {
            event.stopPropagation(); // Prevent closing if clicking on a checkbox
        });
    });
});
