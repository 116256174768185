import { fetchAndDisplayModal } from "./utils/modal_helpers";

document.addEventListener('turbo:before-cache', function() {
    document.querySelectorAll('.modal.show').forEach(function(modal) {
        let modalInstance = bootstrap.Modal.getInstance(modal);
        if (modalInstance) {
            modal.classList.remove('fade');
            modalInstance._backdrop._config.isAnimated = false;
            modalInstance.hide();
            modalInstance.dispose();
        }
    });
});

document.addEventListener('turbo:load', function() {
    // Remove any existing structured data scripts from the head
    document.querySelectorAll("head script[type='application/ld+json']").forEach(script => script.remove());
      
    // Append structured data to head
    if (window.structuredData) {
        const head = document.querySelector('head');
        
        if (window.structuredData.realEstateAgent) {
            const realEstateAgentScript = document.createElement('script');
            realEstateAgentScript.type = 'application/ld+json';
            realEstateAgentScript.textContent = JSON.stringify(window.structuredData.realEstateAgent);
            head.appendChild(realEstateAgentScript);
        }
        
        if (window.structuredData.sitelinksSearchBox) {
            const sitelinksSearchBoxScript = document.createElement('script');
            sitelinksSearchBoxScript.type = 'application/ld+json';
            sitelinksSearchBoxScript.textContent = JSON.stringify(window.structuredData.sitelinksSearchBox);
            head.appendChild(sitelinksSearchBoxScript);
        }

        if (window.structuredData.faq) {
            const faqScript = document.createElement('script');
            faqScript.type = 'application/ld+json';
            faqScript.textContent = JSON.stringify(window.structuredData.faq);
            head.appendChild(faqScript);
        }
        
        if (window.structuredData.article) {
            const articleScript = document.createElement('script');
            articleScript.type = 'application/ld+json';
            articleScript.textContent = JSON.stringify(window.structuredData.article);
            head.appendChild(articleScript);
        }

        if (window.structuredData.product) {
            const productScript = document.createElement('script');
            productScript.type = 'application/ld+json';
            productScript.textContent = JSON.stringify(window.structuredData.product);
            head.appendChild(productScript);
        }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const modalType = urlParams.get('modal');

    if (modalType) {
        const params = Object.fromEntries([...urlParams.entries()].filter(([key]) => !['controller', 'action', 'locale', 'modal'].includes(key)));
        fetchAndDisplayModal(modalType, params);
    }


    //stamp duty
    function toggleLeaseFields() {
        const fixedLease = document.getElementById('fixed_lease');
        const fixedLeaseFields = document.getElementById('fixed-lease-fields');
        const nonFixedLeaseFields = document.getElementById('non-fixed-lease-fields');
    
        if (fixedLease && fixedLeaseFields && nonFixedLeaseFields) {
            if (fixedLease.checked) {
                fixedLeaseFields.style.display = 'block';
                nonFixedLeaseFields.style.display = 'none';
                fixedLeaseFields.querySelectorAll('input').forEach(input => input.required = true);
                nonFixedLeaseFields.querySelectorAll('input').forEach(input => input.required = false);
            } else {
                fixedLeaseFields.style.display = 'none';
                nonFixedLeaseFields.style.display = 'block';
                fixedLeaseFields.querySelectorAll('input').forEach(input => input.required = false);
                nonFixedLeaseFields.querySelectorAll('input').forEach(input => input.required = true);
            }
        }
    }
    
      // Initial toggle on page load
    toggleLeaseFields();

    // Register change event handler for the radio buttons
    document.querySelectorAll('input[name="lease_type"]').forEach(input => {
        input.addEventListener('change', () => {
            toggleLeaseFields();
        });
    });

    const hash = window.location.hash;
    if (hash) {
        const targetElement = document.getElementById(hash.substring(1));
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        
        if (hash.endsWith('-tab')) {
            const tabTrigger = document.querySelector(`[data-bs-target="${hash}-pane"]`);
            if (tabTrigger) {
                const tabInstance = new bootstrap.Tab(tabTrigger);
                tabInstance.show();
            }
        }
    }
});