import { Controller } from "@hotwired/stimulus"
import { fetchWithTurboStream } from "../utils/fetchUtils";

// Connects to data-controller="district-preferences-form"
export default class extends Controller {
  static targets = ["checkbox", "submitButton"]
  static values = { rentalRequirementId: Number }

  async connect() {
    const uniqueId = `form-${Date.now()}`;
    this.element.id = uniqueId;

    const url = `/${window.currentLocale}/district_preferences/form?form_id=${uniqueId}&&rental_requirement_id=${this.rentalRequirementIdValue}`;
    await fetchWithTurboStream(url.toString(), { method: "GET" });
    const messagesContainer = document.querySelector('#messages');
    setTimeout(() => {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }, 100);
  }

  updateCheckboxStates() {
    const checkedBoxes = this.checkboxTargets.filter(cb => cb.checked);
    const uncheckedBoxes = this.checkboxTargets.filter(cb => !cb.checked);

    uncheckedBoxes.forEach(cb => cb.disabled = checkedBoxes.length >= 3);

    const isButtonEnabled = checkedBoxes.length > 0;
    this.submitButtonTarget.disabled = !isButtonEnabled;
    this.submitButtonTarget.classList.toggle('disabled', !isButtonEnabled);
  }

  updateCheckboxPriorities() {
    // Filter out checked boxes and sort them by the time they were checked (using a custom data attribute for timestamp)
    const checkedBoxes = Array.from(this.checkboxTargets)
                              .filter(cb => cb.checked)
                              .sort((a, b) => parseInt(a.dataset.checkedAt) - parseInt(b.dataset.checkedAt));

    // Clear existing priorities and classes
    this.checkboxTargets.forEach(cb => {
      cb.closest('.form-check').classList.remove('custom-checkbox-1', 'custom-checkbox-2', 'custom-checkbox-3');
      const priorityId = cb.dataset.priorityId;
      const hiddenField = document.getElementById(`priority_${priorityId}`);
      if (hiddenField) hiddenField.value = '';
    });

    // Assign new priorities and classes
    checkedBoxes.slice(0, 3).forEach((cb, index) => {
        const priorityClass = `custom-checkbox-${index + 1}`;
        cb.closest('.form-check').classList.add(priorityClass);
        const priorityId = cb.dataset.priorityId;
        const hiddenField = document.getElementById(`priority_${priorityId}`);
        hiddenField.value = index + 1;
    });
  }

  toggleCheckbox(event) {
    this.updateCheckboxStates();
    const cb = event.target;
    if (cb.checked) {
      cb.dataset.checkedAt = Date.now();
    } else {
      // Remove the timestamp and priority when unchecked
      delete cb.dataset.checkedAt;
    }
    this.updateCheckboxPriorities();
  }
}
