import { Controller } from "@hotwired/stimulus"
import { toggleButtonState } from "../utils/buttons";

// Connects to data-controller="contact-form"
export default class extends Controller {
  connect() {
    this.handleSuccess();
  }

  handleSuccess() {
    const form = document.querySelector('#contact form');
    const button = form.querySelector('button');

    button.classList.add('complete');
    button.classList.remove('loading');

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('initBurst', { 
        bubbles: true, 
        detail: { buttonId: button.id }
      }));

      setTimeout(() => {
        form.reset();
        toggleButtonState(button, false);
      }, 2000);
    }, 320);
  }
}
