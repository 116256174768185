import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="space-upload"
export default class extends Controller {
  static targets = ["input", "preview"];

  connect() {
    this.updatePreview();
  }

  inputChange() {
    this.updatePreview();
  }

  updatePreview() {
    const spaceId = this.inputTarget.value.trim();
    if (spaceId.length === 11) {
      this.previewTarget.innerHTML = `
        <iframe width="100%" height="100%" src="https://my.matterport.com/show/?m=${spaceId}" frameborder="0" allowfullscreen allow="xr-spatial-tracking" title="Listing VR"></iframe>
      `;
    } else {
      this.previewTarget.innerHTML = ""; // Clear the preview if the input is not exactly 11 characters
    }
  }
}
