import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video-upload"
export default class extends Controller {
  static values = { url: String }
  static targets = ["input", "preview", "zeroVideoChosen", "oneVideoChosen"]

  connect() {
    this.updateVideoDisplay(this.hasUrlValue ? this.urlValue : null);
  }

  inputChange(event) {
    const file = this.inputTarget.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      this.updateVideoDisplay(url);
    } else {
      this.updateVideoDisplay(null);
    }
  }

  updateVideoDisplay(src) {
    if (src) {
      this.previewTarget.innerHTML = `<video class="h-100" controls><source src="${src}" type="video/mp4">Your browser does not support the video tag.</video>`;
      this.toggleChosenIndicator(true);
    } else {
      this.previewTarget.innerHTML = '';
      this.toggleChosenIndicator(false);
    }
  }

  toggleChosenIndicator(hasVideo) {
    if (hasVideo) {
      this.oneVideoChosenTarget.classList.remove('d-none');
      this.zeroVideoChosenTarget.classList.add('d-none');
    } else {
      this.oneVideoChosenTarget.classList.add('d-none');
      this.zeroVideoChosenTarget.classList.remove('d-none');
    }
  }
}