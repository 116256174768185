import { Controller } from "@hotwired/stimulus"
import { createEstateListItem } from "../utils/autocomplete";

// Connects to data-controller="address-form"
export default class extends Controller {
  static targets = ["input", "suggestions", "error", "phaseGroup", "phaseSelect", "buildingGroup", "buildingSelect", "floorUnit", "searchField", "manualForm", "manualAddress", "floorUnitNotice"];
  static values = { isSelfListing: Boolean };

  connect() {
    this.selectedSuggestionIndex = -1;
  }

  handleFocus() {
    this.search();
  }

  handleInput() {
    this.inputTarget.classList.toggle('search-address-invalid', true);
    this.toggleFormElements(this.phaseGroupTarget, false);
    this.toggleFormElements(this.buildingGroupTarget, false);
    this.toggleFormElements(this.floorUnitTarget, false);

    if (this.hasFloorUnitNoticeTarget) {
      this.toggleFormElements(this.floorUnitNoticeTarget, false);
    }
    
    this.search();
  }

  search() {
    let query = this.inputTarget.value;

    fetch(`/${window.currentLocale}/estates/autocomplete?query=${encodeURIComponent(query)}`)
      .then(response => response.json())
      .then(data => this.displaySuggestions(data));
  }

  displaySuggestions(data) {
    this.clearSuggestions();
    const linkItem = this.suggestionsTarget.querySelector('.link-item');
    this.suggestionsTarget.classList.remove('d-none');
    data.forEach((item) => {
      let listItem = createEstateListItem(item);
      
      listItem.addEventListener('click', () => {
        this.isVillage = item.is_village;
        this.clearSuggestions();
        this.toggleFormElements(this.phaseGroupTarget, false);
        this.toggleFormElements(this.buildingGroupTarget, false);
        this.toggleFormElements(this.floorUnitTarget, false);
    
        if (this.hasFloorUnitNoticeTarget) {
          this.toggleFormElements(this.floorUnitNoticeTarget, false);
        }

        this.inputTarget.value = item.name;
        this.inputTarget.classList.toggle('search-address-invalid', false);
        if (this.hasErrorTarget) {  // Check if the error target exists
          this.errorTarget.classList.add('d-none'); // Hide the error message
        }

        this.toggleNoticeVisibility(this.isVillage && !this.isSelfListingValue);

        this.fetchInfo("phases", item.id);
      });
      this.suggestionsTarget.insertBefore(listItem, linkItem);
    });
  }

  clearSuggestions() {
    const suggestionItems = this.suggestionsTarget.querySelectorAll('button:not(.link-item)');
    suggestionItems.forEach(item => item.remove());
    this.suggestionsTarget.classList.add('d-none');
  }

  navigate(e) {
    let suggestions = this.suggestionsTarget.querySelectorAll('button');
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex + 1) % suggestions.length;
        this.highlightSuggestion(suggestions);
        break;
      
      case 'ArrowUp':
        if (this.selectedSuggestionIndex <= 0) this.selectedSuggestionIndex = suggestions.length;
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex - 1) % suggestions.length;
        this.highlightSuggestion(suggestions);
        break;
      
      case 'Enter':
        if (this.selectedSuggestionIndex > -1) {
          e.preventDefault(); // Prevent form submission
          suggestions[this.selectedSuggestionIndex].click();
        }
        break;
    }
  }

  highlightSuggestion(suggestions) {
    suggestions.forEach((suggestion, index) => {
      suggestion.classList.toggle('bg-body-tertiary', index === this.selectedSuggestionIndex);
    });
  }

  hideSuggestions() {
    setTimeout(() => {
      this.clearSuggestions();
    }, 150);
  }

  async fetchInfo(type, estateId, phaseId = null) {
    try {
      const url = new URL(`${window.currentLocale}/${type}/info`, window.location.origin);
      if (estateId) {
        url.searchParams.append('estateId', estateId);
      }
      
      if (phaseId) {
        url.searchParams.append('phaseId', phaseId); // Append phaseId only for buildings
      }

      const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      });

      // Check if the response is ok (status in the range 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (type === 'phases') {
        if (data.length > 1) {
          this.populateSelect(this.phaseSelectTarget, data);
        } else {
          this.fetchInfo('buildings', estateId);
        }
      } else {
        const populateBuilding = data.length === 1 || (this.isVillage && this.isSelfListingValue);
        this.populateSelect(this.buildingSelectTarget, data, !populateBuilding);

        if (populateBuilding) {
          this.buildingSelectTarget.value = data[0].id;
          this.buildingSelectTarget.dispatchEvent(new Event('change'));
        }
      }
      
    } catch (error) {
        console.error(`Error fetching ${type}:`, error);
    }
  }

  populateSelect(selectElement, items, showElement=true) {    
    // Clear existing options
    Array.from(selectElement.options).forEach(option => {
      if (option.value) { // Only remove options that have a value (not the blank option)
        selectElement.remove(option.index);
      }
    });

    // Populate the select with new options
    items.forEach(item => {
      const option = new Option(item.name, item.id);
      selectElement.appendChild(option);
    });

    if (showElement) {
      this.toggleFormElements(selectElement.parentElement, true);
    } else {
      selectElement.parentElement.classList.add('d-none');
    }

    this.reinitializeController(selectElement.parentElement, 'select');
  }

  onPhaseChange(event) {
    this.toggleFormElements(this.buildingGroupTarget, false);
    this.toggleFormElements(this.floorUnitTarget, false);

    if (this.hasFloorUnitNoticeTarget) {
      this.toggleFormElements(this.floorUnitNoticeTarget, false);
    }

    this.fetchInfo('buildings', null, event.target.value);
  }

  onBuildingChange() {
    this.toggleFormElements(this.floorUnitTarget, true);

    if (this.hasFloorUnitNoticeTarget) {
      this.toggleFormElements(this.floorUnitNoticeTarget, true);
    }
  }

  showManualForm() {
    if (this.inputTarget.value && this.hasManualAddressTarget) {
      this.manualAddressTarget.value = this.inputTarget.value;  // Copy value
    }

    this.toggleFormElements(this.searchFieldTarget, false);
    this.toggleFormElements(this.phaseGroupTarget, false);
    this.toggleFormElements(this.buildingGroupTarget, false);
    this.toggleFormElements(this.floorUnitTarget, false);
    this.errorTarget.classList.add('d-none');

    this.toggleFormElements(this.manualFormTarget, true);

    if (this.hasFloorUnitNoticeTarget) {
      this.toggleFormElements(this.floorUnitNoticeTarget, true);
    }

    this.toggleNoticeVisibility(!this.isSelfListingValue);
  }
  
  showSearchForm(event) {
    event.preventDefault();

    if (this.hasManualAddressTarget && this.manualAddressTarget.value) {
      this.inputTarget.value = this.manualAddressTarget.value;  // Copy value
    }

    this.toggleFormElements(this.searchFieldTarget, true);

    this.inputTarget.focus();
  
    this.toggleFormElements(this.manualFormTarget, false);

    if (this.hasFloorUnitNoticeTarget) {
      this.toggleFormElements(this.floorUnitNoticeTarget, false);
    }

    this.toggleNoticeVisibility(false);
  }
  
  toggleFormElements(target, isRequired) {
    target.classList.toggle('d-none', !isRequired);
    const elements = target.querySelectorAll('input, select, textarea');
    
    elements.forEach(element => {
      element.required = isRequired;
      
      if (!isRequired) {
        element.value = '';
      }
    });
  }

  reinitializeController(element, controllerName) {
    if (element.hasAttribute('data-controller')) {
      element.removeAttribute('data-controller');
    }
    
    setTimeout(function() {
      element.setAttribute('data-controller', controllerName);
    });
  }

  toggleNoticeVisibility(isVisible) {
    const element = document.getElementById('village-house-notice');

    if (element) {
      element.classList.toggle("d-none", !isVisible);
    }
  }
}
