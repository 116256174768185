import { t } from './../i18n';

export function createEstateListItem(item, showListingsCount = false) {
    let listItem = document.createElement('button');
    listItem.type = 'button';
    listItem.classList.add("list-group-item", "list-group-item-action", "d-flex", "justify-content-between", "align-items-center");

    const addressParts = [item.district, item.street_address].filter(Boolean);
    const addressString = addressParts.join(' &#x2022; ');

    const listingsCountHTML = createListingsCountHTML(item.listings_to_lease_count, showListingsCount);

    listItem.innerHTML = `
        <div>
            <span class="fw-medium">${item.name}</span><br>
            <span class="text-secondary">${addressString}</span>
        </div>
        ${listingsCountHTML}
    `;
    return listItem;
}

export function createDistrictListItem(item, showListingsCount = false) {
    let listItem = document.createElement('button');
    listItem.type = 'button';
    listItem.classList.add("list-group-item", "list-group-item-action", "d-flex", "justify-content-between", "align-items-center");

    const districtParts = [item.area, item.district].filter(Boolean);
    const districtString = districtParts.join(' &#x2022; ');

    const listingsCountHTML = createListingsCountHTML(item.listings_to_lease_count, showListingsCount);

    listItem.innerHTML = `
        <div>
            <span class="fw-medium">${item.name}</span><br>
            <span class="text-secondary">${districtString}</span>
        </div>
        ${listingsCountHTML}
    `;

    return listItem;
}

function createListingsCountHTML(count, showListingsCount) {
    if (showListingsCount && count > 0) {
        return `<span class="small text-secondary">${count} ${t('listings')}</span>`;
    }

    return '';
}