import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select"
export default class extends Controller {
  connect() {
    const existingSelected = this.element.querySelector(".select-selected");
    const existingItems = this.element.querySelector(".select-items");

    if (existingSelected) {
      existingSelected.remove();
    }

    if (existingItems) {
      existingItems.remove();
    }
    
    this.selElmnt = this.element.querySelector("select");
    this.selectSelected = document.createElement("DIV");
    this.selectSelected.setAttribute("class", "select-selected");
    this.selectSelected.innerHTML = this.selElmnt.options[this.selElmnt.selectedIndex].innerHTML;

    this.element.appendChild(this.selectSelected);
    this.selectItems = document.createElement("DIV");
    this.selectItems.setAttribute("class", "select-items select-hide");
    for (let j = 1; j < this.selElmnt.length; j++) {
      const c = document.createElement("DIV");
      c.innerHTML = this.selElmnt.options[j].innerHTML;
      c.addEventListener("click", (e) => {
        this.selectOption(e);
      });
      this.selectItems.appendChild(c);
    }
    this.element.appendChild(this.selectItems);
    this.selectSelected.addEventListener("click", (e) => {
      this.element.querySelector(".select-items").classList.toggle("select-hide");
      e.currentTarget.classList.toggle("select-arrow-active");
    });

    this.focusHandler = this.focusHandler.bind(this);
    this.selElmnt.addEventListener('focus', this.focusHandler);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.handleClickOutside);

    this.handleKeyDown = this.handleKeyDown.bind(this); // Bind in connect
    this.selElmnt.addEventListener("keydown", this.handleKeyDown);

    this.currentIndex = -1;
  }

  disconnect() {
    this.selElmnt.removeEventListener('focus', this.focusHandler);
    document.removeEventListener("click", this.handleClickOutside);
    this.selElmnt.removeEventListener("keydown", this.handleKeyDown);
  }

  focusHandler() {
    this.selectSelected.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.selectSelected.click();
  }

  handleClickOutside(e) {
    if (e.target !== this.selectSelected) {
      this.selectItems.classList.add("select-hide");
      this.selectSelected.classList.remove("select-arrow-active");
    }
  }

  selectOption(e) {
    const items = this.selectItems.querySelectorAll("div");
    items.forEach(item => {
      item.classList.remove("bg-light");
    });
    
    for (let i = 0; i < this.selElmnt.length; i++) {
      if (this.selElmnt.options[i].innerHTML == e.currentTarget.innerHTML) {
        this.selElmnt.selectedIndex = i;
        this.currentIndex = i;

        const event = new Event('change', {
          bubbles: true,
          cancelable: true
        });
        this.selElmnt.dispatchEvent(event);

        this.selectSelected.innerHTML = e.currentTarget.innerHTML;
        const sameAsSelected = this.element.querySelector(".same-as-selected");
        if (sameAsSelected) {
          sameAsSelected.removeAttribute("class");
        }
        e.currentTarget.setAttribute("class", "same-as-selected");
        break;
      }
    }
    this.selectSelected.click();
  }

  handleKeyDown(e) {
    const items = this.selectItems.querySelectorAll("div");

    if (e.key === "ArrowDown") {
      e.preventDefault();

      if (this.currentIndex >= 0) {
        items[this.currentIndex].classList.remove("bg-light");
      }

      this.currentIndex = (this.currentIndex + 1) % items.length; // Move down
      items[this.currentIndex].classList.add("bg-light");
    } else if (e.key === "ArrowUp") {
      e.preventDefault();

      if (this.currentIndex >= 0) {
        items[this.currentIndex].classList.remove("bg-light");
      }

      this.currentIndex = (this.currentIndex - 1 + items.length) % items.length; // Move up
      items[this.currentIndex].classList.add("bg-light");
    } else if (e.key === "Enter") {
      e.preventDefault();

      if (this.currentIndex >= 0) {
        this.selectOption({ currentTarget: items[this.currentIndex] });
      }
    }
  }
}