import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="currency"
export default class extends Controller {

  connect() {
    this.formatInput();
    this.element.addEventListener("input", this.formatInput.bind(this));
  }

  formatInput() {
    let value = this.element.value.replace(/,/g, ''); // Remove existing commas
    if (value) {
      this.element.value = new Intl.NumberFormat('en-HK', { style: 'decimal' }).format(value);
    }
  }

  disconnect() {
    this.element.removeEventListener("input", this.formatInput.bind(this));
  }
}
