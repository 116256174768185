import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chinese-converter"
export default class extends Controller {
  static targets = ["sourceText", "resultFrame", "targetLanguage", "toggleButton"]
  static values = { toTraditional: String, toSimplified: String, traditional: String, simplified: String, input: String, output: String }

  toggle() {
    const currentLanguage = this.targetLanguageTarget.value;
    if (currentLanguage === 'zh-CN') {
      this.targetLanguageTarget.value = 'zh-HK';
      this.toggleButtonTarget.innerText =  this.toTraditionalValue;
      this.sourceTextTarget.placeholder = `${this.simplifiedValue} (${this.inputValue})`;
      this.resultFrameTarget.innerHTML = `${this.traditionalValue} (${this.outputValue})`;
    } else {
      this.targetLanguageTarget.value = 'zh-CN';
      this.toggleButtonTarget.innerText =  this.toSimplifiedValue;
      this.sourceTextTarget.placeholder = `${this.traditionalValue} (${this.inputValue})`;
      this.resultFrameTarget.innerHTML = `${this.simplifiedValue} (${this.outputValue})`;
    }
    // Reset text area and result frame
    this.sourceTextTarget.value = "";
  }
}
