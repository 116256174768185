document.addEventListener('turbo:load', function() {
    document.addEventListener('click', function(event) {
        if (event.target.closest('.show-email-form-button')) {
            var container = event.target.closest('.sign-in-container');
            container.querySelector('.email-section').classList.remove('d-none');
            container.querySelector('.otp-section').classList.add('d-none');
            event.target.closest('.show-email-form-button').classList.add('d-none');
            container.querySelector('.show-otp-form-button').classList.remove('d-none');
        } else if (event.target.closest('.show-otp-form-button')) {
            var container = event.target.closest('.sign-in-container');
            container.querySelector('.otp-section').classList.remove('d-none');
            container.querySelector('.email-section').classList.add('d-none');
            event.target.closest('.show-otp-form-button').classList.add('d-none');
            container.querySelector('.show-email-form-button').classList.remove('d-none');
        }
    });
});
  