import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="area-selector"
export default class extends Controller {
  connect() {
    const checkedAreas = Array.from(document.querySelectorAll('input[name="area[]"]:checked'))
    .map(input => input.value);

    // Gather all district checkboxes
    const districtCheckboxes = this.element.querySelectorAll('input[name="district[]"]');

    // Check district checkboxes where their area attribute is in the list of checked areas
    districtCheckboxes.forEach((checkbox) => {
      if (checkedAreas.includes(checkbox.dataset.area)) {
        checkbox.checked = true;
      }
    });
  }

  selectAll(event) {
    const areaValue = event.currentTarget.value;
    const isChecked = event.target.checked;
    const checkboxes = document.querySelectorAll('input[name="district[]"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.dataset.area === areaValue) {
        checkbox.checked = isChecked;
      }
    });
  }
}
