import { Controller } from "@hotwired/stimulus"
import { openModal, updateModalType } from '../utils/modal_helpers';

// Connects to data-controller="modals"
export default class extends Controller {
  static values = {
    modalType: String,
    id: { type: String, default: 'universal-modal' },
    preventConnect: { type: Boolean, default: false }
  }

  connect() {
    if (!this.preventConnectValue) {
      updateModalType(this.idValue, this.modalTypeValue);
      openModal(this.idValue);
    }
  }

  handleClick(event) {
    const modal = document.getElementById(this.idValue);
    if (modal && modal.getAttribute('data-modal-type') === this.modalTypeValue) {
      openModal(this.idValue);
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
