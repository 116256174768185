import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-enforcement"
export default class extends Controller {
  static targets = ["termsCheckbox", "warningText"];

  enforceCondition(event) {
    // Prevent form submission if the checkbox is not checked
    if (!this.termsCheckboxTarget.checked) {
      this.warningTextTarget.classList.remove("d-none");
      event.preventDefault(); // Stop the form submission
      event.stopImmediatePropagation(); // Prevent further event handling
    } else {
      this.warningTextTarget.classList.add("d-none"); // Hide warning text if checkbox is checked
    }
  }
}
