import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["content", "toggleButton"]
  static values = { openText: String, closeText: String }

  toggle() {
    this.contentTarget.classList.toggle("open");
    if (this.contentTarget.classList.contains("open")) {
      this.toggleButtonTarget.textContent = this.closeTextValue;
    } else {
      this.toggleButtonTarget.textContent = this.openTextValue;
    }
  }
}
