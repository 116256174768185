import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lazy-video"
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.observer = new IntersectionObserver(this.loadVideo.bind(this), {
      rootMargin: '100px',
      threshold: 0
    });
    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.unobserve(this.element);
  }

  loadVideo(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.element.innerHTML = `<video autoplay muted loop playsinline class="w-100 h-auto">
                                    <source src="${this.urlValue}" type="video/mp4">
                                    Your browser does not support the video tag.
                                  </video>`;
        observer.unobserve(entry.target);  // Optionally stop observing after loading
      }
    });
  }
}
