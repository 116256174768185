export function updateButtonState(button, isDisabled) {
    if (button) {
        button.disabled = isDisabled;
    }
}

export function toggleButtonState(button, isLoading) {
    
    if (button instanceof HTMLElement) {
        button.disabled = isLoading;
        const spinner = button.querySelector(".spinner-border");

        if (spinner) {
            spinner.classList.toggle('d-none', !isLoading);
            button.querySelector('[role="status"]').classList.toggle('visually-hidden', isLoading);
        }
    } else {
        console.warn("toggleButtonState was called with an invalid button element", button);
    }
}