import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-button-state"
export default class extends Controller {
  connect() {
    this.updateButtonState();
    this.setupEventListeners();
  }

  setupEventListeners() {
    // Attach input event listeners to all inputs within the form
    this.element.querySelectorAll('input, select, textarea').forEach(input => {
      input.addEventListener('input', () => {
        this.updateButtonState();
      });
    });
  }

  updateButtonState() {
    // Assume button is the first button target
    const button = this.element.querySelector('button');
    const isFormValid = this.element.checkValidity();
    const allTelValid = Array.from(this.element.querySelectorAll('input[type="tel"]')).every(input => input.classList.contains('number-valid'));

    if (isFormValid && allTelValid) {
      button.classList.replace('bg-gray', 'bg-black');
      button.disabled = false;
    } else {
      button.classList.replace('bg-black', 'bg-gray');
      button.disabled = true;
    }
  }
}
