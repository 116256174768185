import { Controller } from "@hotwired/stimulus"
import { createChatChannel, createGeneralChatChannel } from '../channels/chat_channel';
import { createSummaryHtml, extractConversationDetails, storeConversationDetails, generateMessageHTML, updateChatOverview, refreshUnreadCount, generateMessageContextHTML, getLocalStorageItem, setLocalStorageItem, updateChatDisplayElements } from "../utils/chat";
import autosize from "autosize";
import { isMobile } from "../utils/utils";

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = ["bubble", "bubblePrompt", "generalPrompt", "moreHelpPrompt", "overview", "window", "list", "messages", "messageContent", "title", "restart", "newMessageForm"]

  async connect() {
    const conversationId = getLocalStorageItem("conversationId");
    this.generalChatChannel = createGeneralChatChannel();
    this.loadChatSummaries();

    if (this.isNewListingPage()) {
      this.minimizeChat();
      this.closeOverview();
      this.hidePrompt();
      if (!isMobile() && !getLocalStorageItem('interactedWithMoreHelpChat')) {
        setTimeout(async () => {
          this.showMoreHelpPrompt();
        }, 5000);
      }
    } else {
      if (this.windowTarget.classList.contains('active')) {
        const conversationDetails = extractConversationDetails(this.windowTarget);
        await this.initializeChat(conversationDetails);
      } else if (conversationId) {
        await this.startNewConversation({ conversation_id: conversationId });
      } else {
        if (this.hasMoreHelpPromptTarget && !this.moreHelpPromptTarget.classList.contains('d-none')) {
          this.hidePrompt();
        }
        if (this.hasGeneralPromptTarget && this.generalPromptTarget.classList.contains('d-none') && !getLocalStorageItem('interactedWithGeneralChat')) {
          setTimeout(async () => {
            this.showGeneralPrompt();
          }, 5000);
        }
      }
    }

    this.boundHandleChatStart = this.handleChatStart.bind(this);
    document.addEventListener('chat:start', this.boundHandleChatStart);

    this.isLoadingValue = false;
  }

  disconnect() {
    this.generalChatChannel.unsubscribe();

    this.unsubscribeFromChatChannel();
    document.removeEventListener('chat:start', this.boundHandleChatStart);
  }

  handleChatStart(event) {
    this.startNewConversation(event.detail.init);
  }

  async handleBubbleClick() {
    this.bubbleTarget.classList.add('active');
    this.hidePrompt();

    if (this.isNewListingPage()) {
      setLocalStorageItem('interactedWithMoreHelpChat', true, 24);
    } else {
      setLocalStorageItem('interactedWithGeneralChat', true, 24);
    }
    await this.startNewConversation();

    this.overviewTarget.classList.remove('d-none');
    setTimeout(() => {
      this.overviewTarget.classList.add('active');
    }, 10);
    this.loadChatSummaries();
  }

  async loadChatSummaries(forceLoad = false) {
    if (this.isLoadingValue || (!forceLoad && this.listTarget.children.length > 0)) return;
    this.isLoadingValue = true;

    try {
      const offset = this.listTarget.children.length;
      const response = await fetch(`/${window.currentLocale}/conversations/summaries?offset=${offset}`);
      const { summaries, totalUnreadCount } = await response.json();

      let summariesListHtml = summaries.map(summary => createSummaryHtml(summary)).join('');
      this.listTarget.insertAdjacentHTML('beforeend', summariesListHtml);

      refreshUnreadCount(totalUnreadCount);
      this.isLoadingValue = false;
    } catch (error) {
      console.error('Failed to load chat summaries:', error);
      this.isLoadingValue = false;
    }
  }

  onScroll() {
    let bottom = this.listTarget.scrollHeight - this.listTarget.scrollTop === this.listTarget.clientHeight;
    if (bottom) {
      this.loadChatSummaries(true);
    }
  }

  handleChatSummaryClick(e) {
    const conversationDetails = extractConversationDetails(e.currentTarget);
    this.initializeChat(conversationDetails);
  }

  async initializeChat(conversationDetails) {
    storeConversationDetails(this.windowTarget, conversationDetails);
    updateChatDisplayElements(this.titleTarget, this.restartTarget, conversationDetails);
    this.windowTarget.classList.remove('d-none');
    setTimeout(() => {
      this.windowTarget.classList.add('active');
    }, 10);
    this.newMessageFormTarget.classList.toggle('d-none', conversationDetails.status === 'closed');

    if (conversationDetails.id) {
      await this.fetchMessages(conversationDetails);
    }

    this.unsubscribeFromChatChannel();
    this.chatChannel = createChatChannel(conversationDetails);
  }

  handleNewMessageSubmit(e) {
    e.preventDefault();
    const messageContent = this.messageContentTarget.value;
    this.chatChannel.speak(messageContent);
    this.messageContentTarget.value = '';
    autosize.update(this.messageContentTarget);
  }

  checkForEnter(event) {
    if (event.keyCode === 13 && !event.shiftKey) { // 13 is the Enter key
      this.handleNewMessageSubmit(event);
    }
  }

  handleReplyClick(event) {
    let replyContent = event.currentTarget.getAttribute('data-reply-value');
    this.chatChannel.speak(replyContent);
    this.messageContentTarget.value = '';
  }

  async fetchMessages(conversationDetails) {
    try {
      const response = await fetch(`/${window.currentLocale}/messages?conversation_id=${encodeURIComponent(conversationDetails.id)}`);
      const { messages, displayDates } = await response.json();

      let messagesHtml = '';
      let lastMessage = null;
      let lastDisplayedDate = null;

      messages.forEach(message => {
        if (displayDates && message.date !== lastDisplayedDate) {
          messagesHtml += generateMessageContextHTML(message.date);
          lastDisplayedDate = message.date; // Update the last displayed date
        }

        messagesHtml += generateMessageHTML(message, true);
        lastMessage = message;
      });

      this.messagesTarget.innerHTML = messagesHtml; // Update the messages target
      this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight; // Scroll to the bottom

      if (lastMessage) {
        lastMessage.unreadCount = 0;
        lastMessage.conversationDetails = conversationDetails;
        updateChatOverview(lastMessage, false);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  }

  closeOverview() {
    this.overviewTarget.classList.replace('active', 'd-none');
    this.bubbleTarget.classList.remove('active');
  }

  restartChat() {
    const conversationDetails = extractConversationDetails(this.windowTarget);
    const chatSummary = this.listTarget.querySelector(`[data-conversation-id="${conversationDetails.id}"]`);

    if (chatSummary) {
      chatSummary.remove();
    }

    this.startNewConversation({ conversation_id: conversationDetails.id, restart: true });
  }

  minimizeChat() {
    this.unsubscribeFromChatChannel();
    this.windowTarget.classList.replace('active', 'd-none');
    setLocalStorageItem("conversationId", null);

    const chatSummaries = this.listTarget.querySelectorAll('.chat-summary');
    if (chatSummaries.length === 1) {
        this.closeOverview();
    }
  }

  async startNewConversation(initData) {
    try {
      const response = await fetch(`/${window.currentLocale}/conversations/start`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(initData)
      });
      const responseData = await response.json();

      if (responseData.error) {
        this.triggerNotification('error', responseData.error);
      } else if (responseData.conversationDetails) {
        window.userId = responseData.userId;
        this.bubbleTarget.classList.add('active');
        this.overviewTarget.classList.remove('d-none');
        setTimeout(() => {
          this.overviewTarget.classList.add('active');
        }, 10);
        setLocalStorageItem('conversationId', responseData.conversationDetails.id);
        await this.initializeChat(responseData.conversationDetails);
      }
    } catch (error) {
      console.error('Error starting a new conversation:', error);
    }
  }

  async handleFileUpload(event) {
    const files = event.target.files;

    if (files.length) {
      Array.from(files).forEach(file => {
        if (file.type.startsWith('image/')) {
          this.chatChannel.speak('', file);  // Call 'speak' for each image
        }
      });

      event.target.value = ''; 
    }
  }

  unsubscribeFromChatChannel() {
    if (this.chatChannel) {
      this.chatChannel.unsubscribe();
      this.chatChannel = null;
    }
  }

  triggerNotification(type, message) {
    const notificationsControllerElement = document.createElement('div');
    notificationsControllerElement.setAttribute('data-controller', 'notifications');
    notificationsControllerElement.setAttribute('data-notifications-type-value', type);
    notificationsControllerElement.setAttribute('data-notifications-message-value', message);
    
    document.getElementById('triggers').appendChild(notificationsControllerElement);
  }

  isNewListingPage() {
    const pattern = /\/(assisted_listings|self_listings)\/new$/;
    const isMatch = pattern.test(window.location.href);
    return isMatch;
  }

  showGeneralPrompt() {
    if (this.hasBubblePromptTarget) {
      this.generalPromptTarget.classList.remove("d-none");
      this.moreHelpPromptTarget.classList.add("d-none");
      this.bubblePromptTarget.classList.add("active");
    }
  }

  showMoreHelpPrompt() {
    if (this.hasBubblePromptTarget) {
      this.moreHelpPromptTarget.classList.remove("d-none");
      this.generalPromptTarget.classList.add("d-none");
      this.bubblePromptTarget.classList.add("active");
    }
  }

  hidePrompt() {
    if (this.hasBubblePromptTarget) {
      this.bubblePromptTarget.classList.remove("active");
      this.generalPromptTarget.classList.add("d-none");
      this.moreHelpPromptTarget.classList.add("d-none");
    }
  }
}
