function cross_autocomplete(dict, areaKey) {
    const inp = {};
    var currentFocus;
    for (const [key, arr] of Object.entries(dict)) {
        inp[key] = document.getElementById(key);

        const events = ["input", "click", "focusin"];

        for (const event of events) {
            inp[key].addEventListener(event, function(e) {
                var a, b, i, val = this.value;

                closeAllLists();
                currentFocus = -1;
                a = document.createElement("DIV");
                a.setAttribute("id", this.id + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                this.parentNode.appendChild(a);

                var dropdown_values = [];

                for (i = 0; i < arr.length; i++) {
                    let display = true;

                    for (const key1 of Object.keys(dict)) {
                        if (key == key1) {
                            display &= arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase(); 
                        }
                        else {
                            const val_1 = document.getElementById(key1).value;
                            if (val_1) {display &= document.getElementById(key1).value == dict[key1][i];}
                        }
                    }

                    if (display && ! dropdown_values.includes(arr[i])) {
                        b = document.createElement("DIV");
                        b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                        b.innerHTML += arr[i].substr(val.length);
                        b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                        b.addEventListener("click", function(e) {
                            inp[key].value = this.getElementsByTagName("input")[0].value;
                            inp[key].focus();
                            closeAllLists();
                            inp[key].blur();
                        });
                        a.appendChild(b);
                        dropdown_values.push(arr[i]);
                    }
                }
            });
        }

        inp[key].addEventListener("keydown", function(e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
              } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
              } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                  /*and simulate a click on the "active" item:*/
                  if (x) x[currentFocus].click();
                }
              }
        });
    
        inp[key].addEventListener("focusout", function(e) {
            setTimeout(() => {
                var index = -1;
                var is_unique = false;

                const index_array = [];

                for (let i = 0; i < dict[key].length; i++) {
                    let add_index = true;
                    for (const key1 of Object.keys(dict)) {
                        const val_1 = document.getElementById(key1).value;
                        if (val_1) {
                            add_index &= val_1 == dict[key1][i];
                        }

                    }
                    if (add_index) {
                        index_array.push(i);
                    }
                }

                if (index_array.length == 1) {
                    const index = index_array[0];

                    for (const key1 of Object.keys(dict)) {
                        const input = document.getElementById(key1);
                        if (! input.value) {
                            input.value = dict[key1][index];
                        }

                    }
                }

                else {
                    potential_areas = [...new Set(index_array.map(x => dict[areaKey][x]))];
                    if (potential_areas.length == 1) {
                        document.getElementById(areaKey).value = potential_areas[0];
                    }
                }

                const autocompleteItems = inp[key].parentNode.querySelectorAll('.autocomplete-items');
                autocompleteItems.forEach(function(item) {
                    item.parentNode.removeChild(item);
                });
            }, 150);
        });
    }


    function addActive(x) {
        if (!x) return false;
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        x[currentFocus].classList.add("autocomplete-active");
    }
    
    function removeActive(x) {
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

    function closeAllLists() {
        var x = document.getElementsByClassName("autocomplete-items");

        for (var i = 0; i < x.length; i++) {
            x[i].parentNode.removeChild(x[i]);
        }
    }
}


document.addEventListener('turbo:load', function() {
    if (typeof gon !== 'undefined' && gon.district_list && gon.area_list && document.getElementById('listing_area')) {
        cross_autocomplete({"listing_district": gon.district_list, "listing_area": gon.area_list}, "listing_area");
    }
});