import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="highlights"
export default class extends Controller {
  static targets = ["input", "error"]

  connect() {
    this.initializeHighlights();
  }

  initializeHighlights() {
    const options = this.inputTarget.options;
    Array.from(options).forEach(option => {
      const button = this.element.querySelector(`[data-highlight-id='${option.value}']`);
      if (option.selected && button) {
        button.classList.add('btn-info');
        button.classList.remove('btn-outline-info');
      }
    });
  }

  toggleHighlight(event) {
    const button = event.currentTarget;
    const highlightId = button.dataset.highlightId;
    const option = this.inputTarget.querySelector(`option[value='${highlightId}']`);

    if (button.classList.contains('btn-info')) {
      option.selected = false;
      button.classList.remove('btn-info');
      button.classList.add('btn-outline-info');
    } else {
      // Only add the ID if not already present and less than 3 are selected
      if (Array.from(this.inputTarget.selectedOptions).length >= 3) {
        return; // Prevent further actions
      }
      option.selected = true;
      button.classList.add('btn-info');
      button.classList.remove('btn-outline-info');
      this.errorTarget.classList.add('d-none');
    }
  }
}