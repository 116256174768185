import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = { url: String, copiedMessage: String, copyToClipboardMessage: String }

  connect() {
    this.tooltip = new bootstrap.Tooltip(this.element, {
      title: this.element.getAttribute('title') // or any default title you want
    });
  }

  copy() {
    navigator.clipboard.writeText(this.urlValue).then(() => {
      this.tooltip.setContent({ '.tooltip-inner': this.copiedMessageValue });

      setTimeout(() => {
        this.tooltip.setContent({ '.tooltip-inner': this.copyToClipboardMessageValue });
      }, 2000);
    }, (err) => {
      // Handle any errors
      console.error("Error copying to clipboard: ", err);
    });
  }
}
