import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="appointment"
export default class extends Controller {
  static targets = ["type", "tenantFields"]

  connect() {
    this.toggleTenantFields();
  }

  toggleTenantFields() {
    const typesNeedingTenantInfo = ['viewing', 'contract_signing'];
    const needsTenantInfo = typesNeedingTenantInfo.includes(this.typeTarget.value);

    this.tenantFieldsTarget.classList.toggle('d-none', !needsTenantInfo);

    const inputs = this.tenantFieldsTarget.querySelectorAll('input');
    inputs.forEach(input => {
      input.required = needsTenantInfo;
      input.disabled = !needsTenantInfo;
    });
  }
}
