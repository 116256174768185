import { Controller } from "@hotwired/stimulus"
import { joinForLocale } from "../utils/text";

// Connects to data-controller="search-bar"
export default class extends Controller {
  static targets = ["locationInput", "priceInput", "moreOptionsInput", "separator", "locationCheckbox", "bedrooms", "moreOptionsCheckbox"]

  expandLocationInput() {
    this.toggleVisibility(this.locationInputTarget);
  }

  expandPriceInput() {
    this.toggleVisibility(this.priceInputTarget);
  }

  expandMoreOptionsInput() {
    this.toggleVisibility(this.moreOptionsInputTarget);
  }

  toggleVisibility(activeTarget) {
    // Hide all targets
    const targets = [this.locationInputTarget, this.priceInputTarget, this.moreOptionsInputTarget];
    targets.forEach(target => {
      if (target !== activeTarget) {
        target.classList.add('d-none');
      }
    });

    // Toggle the active target and the separator
    activeTarget.classList.toggle('d-none');
    this.separatorTarget.classList.toggle('d-none', activeTarget.classList.contains('d-none'));
  }

  updateLocationSubtitle() {
    let selectedLocations = [];

    this.locationCheckboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        const label = checkbox.closest('.form-check').querySelector('label');
        selectedLocations.push(label.textContent.trim());
      }
    });

    const subtitle = document.getElementById("location-subtitle");
    subtitle.textContent = selectedLocations.length > 0 ? joinForLocale(selectedLocations) : subtitle.dataset.default;
  }

  updateMoreOptionsSubtitle() {
    const selectedOptions = [];

    if (this.bedroomsTarget.value) {
      selectedOptions.push(`${this.bedroomsTarget.value}+ ${this.bedroomsTarget.dataset.label}`);
    }

    this.moreOptionsCheckboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        selectedOptions.push(checkbox.dataset.label);
      }
    });

    const subtitle = document.getElementById("more-options-subtitle");
    subtitle.textContent = selectedOptions.length > 0 ? joinForLocale(selectedOptions) : subtitle.dataset.default;
  }
}