import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="legal"
export default class extends Controller {
  static targets = ["landSearchDetails", "rvdDetails"]

  toggleLandSearchField(event) {
    const isAvailable = event.target.value === 'true';
    
    // Toggle the .d-none class based on the radio button value
    if (isAvailable) {
      this.landSearchDetailsTarget.classList.remove('d-none');
      this.landSearchDetailsTarget.querySelector('input[type="file"]').required = true;
    } else {
      this.landSearchDetailsTarget.classList.add('d-none');
      this.landSearchDetailsTarget.querySelector('input[type="file"]').required = false;
      this.landSearchDetailsTarget.querySelector('input[type="file"]').value = '';
    }
  }

  toggleRvdField(event) {
    const isAvailable = event.target.value === 'true';
    
    // Toggle the .d-none class based on the radio button value
    if (isAvailable) {
      this.rvdDetailsTarget.classList.remove('d-none');
      this.rvdDetailsTarget.querySelector('input[type="file"]').required = true;
    } else {
      this.rvdDetailsTarget.classList.add('d-none');
      this.rvdDetailsTarget.querySelector('input[type="file"]').required = false;
      this.rvdDetailsTarget.querySelector('input[type="file"]').value = '';
    }
  }
}
