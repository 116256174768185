import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-generator"
export default class extends Controller {
  static targets = ["textArea"]

  generateContent() {
    const { currentContent, currentLocale, fieldType, csrfToken } = this.prepareContentGeneration();

    // Check if required fields are populated
    var district = document.getElementById('listing_district').value;
    var propertyType = document.getElementById('listing_property_type').value;
    var petsAllowed = document.getElementById('listing_pets_allowed').value;

    if (!district || !propertyType || !petsAllowed) {
      console.error("Required fields are not populated");
      return; // Exit the function if any field is empty
    }

    fetch('/' + this.mapLocaleCode(currentLocale) + '/generate_content', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        keywords: currentContent,
        field_type: fieldType,
        district: district, 
        property_type: propertyType, 
        pets_allowed: petsAllowed 
      })
    })
    .then(response => response.json())
    .then(data => {
      this.textAreaTarget.value = data.content;
    })
    .catch(error => {
      console.error("Could not generate content:", error);
    });
  }

  translateContent() {
    const { currentContent, currentLocale, fieldBaseName, csrfToken, fieldType } = this.prepareContentGeneration();

    var prefixWithDash = fieldType === 'description';

    ['zh_hk', 'en', 'zh_cn'].forEach((locale) =>  {
      if (locale !== currentLocale) {
        var targetTextAreaId = fieldBaseName + '_' + locale;
        var targetUrl = '/' +  this.mapLocaleCode(locale) + '/translate_text';

        fetch(targetUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          body: JSON.stringify({ 
            text: currentContent,
            prefix_with_dash: prefixWithDash
          })
        })
        .then(response => response.json())
        .then(data => {
          document.getElementById(targetTextAreaId).value = data.translation;
        })
        .catch(error => {
          console.error("Translation error for " + locale + ":", error);
        });
      }
    });
  }

  mapLocaleCode(locale) {
    switch (locale) {
      case 'zh_hk':
        return 'zh-HK';
      case 'zh_cn':
        return 'zh-CN';
      default:
        return locale; // For 'en' and any other locale codes that don't need transformation
    }
  }

  prepareContentGeneration() {
    const textArea = this.textAreaTarget;
    const textAreaId = textArea.id;
    const currentContent = textArea.value;
    const parts = textAreaId.split('_');
    const currentLocale = parts.slice(2).join('_');
    const fieldType = parts[1];
    const fieldBaseName = parts.slice(0, 2).join('_');
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    return { currentContent, currentLocale, fieldType, csrfToken, fieldBaseName };
  }
}
