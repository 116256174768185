document.addEventListener('turbo:load', function() {
    document.querySelectorAll('.button-radio-label').forEach(label => {
        label.addEventListener('click', function() {
            // Remove 'selected' class from all labels in the same form-check group
            let container = this.closest('.button-radio-container');
            container.querySelectorAll('.button-radio-label').forEach(lbl => {
                lbl.classList.remove('selected');
            });
            
            // Add 'selected' class to the clicked label
            this.classList.add('selected');
            
            // Find the associated radio button and check it
            let radioButtonId = this.getAttribute('for');
            document.getElementById(radioButtonId).checked = true;
        });
    });
    
    document.querySelectorAll('input[type="file"]').forEach(input => {
        input.addEventListener('change', function() {
            let maxAttr = this.getAttribute("max");
            
            // If max attribute is not set, just return
            if (!maxAttr) return;

            let maxFileSize;
            let unit;
            
            if (maxAttr.toUpperCase().endsWith("KB")) {
                maxFileSize = parseFloat(maxAttr);
                unit = "KB";
            } else if (maxAttr.toUpperCase().endsWith("MB")) {
                maxFileSize = parseFloat(maxAttr);
                unit = "MB";
            } else {
                // Unhandled unit
                console.error("Unhandled file size unit in max attribute. Please use KB or MB.");
                return;
            }

            // Check each file
            for (let i = 0; i < this.files.length; i++) {
                let fileSize;
                
                if (unit === "KB") {
                    fileSize = this.files[i].size / 1024;
                } else {
                    fileSize = this.files[i].size / (1024 * 1024);
                }

                if (fileSize > maxFileSize) {
                    alert(`File ${this.files[i].name} should not exceed ${maxFileSize} ${unit}.`);
                    this.value = "";
                    return; // Exit the loop and function since we found an oversized file
                }
            }
        });
    });
});