import { Controller } from "@hotwired/stimulus"
import { CountUp } from 'countup.js';

// Connects to data-controller="count-up"
export default class extends Controller {
  connect() {
    const endValue = this.element.dataset.value;
    const options = {
      duration: 2.5,
      separator: ',',
      decimal: '.',
    };
    const countUp = new CountUp(this.element, endValue, options);
    if (!countUp.error) {
      countUp.start();
    } else {
      console.error(countUp.error);
    }
  }
}
