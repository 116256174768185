import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pdf-extractor"
export default class extends Controller {
  connect() {
    console.log("PDF upload controller connected");
  }

  extractDataFromLandSearch(event) {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('pdf_file', file);

    fetch('/legal/extract_data_from_land_search', {
      method: 'POST',
      body: formData, 
      headers: { 'Accept': 'application/json', 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        if (data.data.land_search_address !== null) {
          document.getElementById('listing_land_search_address').value = data.data.land_search_address;
        }
        if (data.data.owners_names !== null) {
          document.getElementById('listing_owners_names').value = data.data.owners_names;
        }
      }
    })
    .catch(error => console.error('Error uploading file:', error));
  }

  extractDataFromRvd(event) {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('pdf_file', file);

    fetch('/legal/extract_data_from_rvd', {
      method: 'POST',
      body: formData, 
      headers: { 'Accept': 'application/json', 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Check if saleable_area is not null and assign it to the input
        if (data.data.saleable_area !== null) {
          document.getElementById('listing_saleable_area').value = data.data.saleable_area;
        }
        // Check if construction_year is not null and assign it to the input
        if (data.data.construction_year !== null) {
          document.getElementById('listing_construction_year').value = data.data.construction_year;
        }
      }
    })
    .catch(error => console.error('Error uploading file:', error));
  }
}
