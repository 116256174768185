import { Controller } from "@hotwired/stimulus"
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// Connects to data-controller="map"
export default class extends Controller {
  static targets = ["map", "levelFilter", "categoryLightFilter", "religionFilter"]
  static values = { 
    zoom: Number,
    centerLatitude: Number,
    centerLongitude: Number,
    fetchCombinedData: Boolean,
    lazyLoad: Boolean,
    markersUrl: String,
  }

  connect() {
    this.listingMarkers = [];
    this.schoolMarkers = [];

    if (this.lazyLoadValue) {
      this.mapLoaded = false;
      this.handleScroll = this.handleScroll.bind(this);
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      this.initGoogleMaps();
      this.initMap();
    }
  }

  disconnect() {
    if (this.lazyLoadValue) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll() {
    if (!this.mapLoaded && this.isInViewport(this.mapTarget)) {
      this.initGoogleMaps();
      this.initMap();
      this.mapLoaded = true;
    }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
      rect.bottom > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.right > 0
    );
  }

  initGoogleMaps() {
    (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
      key: "AIzaSyB9qFVE2y-pmaoa1xc_gereM0QIAjOxTfs",
      v: "weekly",
      // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
      // Add other bootstrap parameters as needed, using camel case.
    });
  }

  async initMap() {
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
      "marker",
    );

    const center = { lat: this.centerLatitudeValue, lng: this.centerLongitudeValue };

    this.map = new Map(this.mapTarget, {
        center: center,
        zoom: this.zoomValue || 11,
        mapId: "DEMO_MAP_ID",
    });

    const markersData = await this.fetchMarkersData();

    if (markersData) {
      markersData.forEach(markerData => {
        const markerOptions = {
          position: { lat: markerData.lat, lng: markerData.lng },
          map: this.map,
        };

        if (markerData.title) {
          markerOptions.title = markerData.title;
        }

        if (markerData.icon) {
          markerOptions.content = this.createDomNodeFromHtml(markerData.icon);
        }

        const marker = new google.maps.marker.AdvancedMarkerElement(markerOptions);

        if (markerData.infoHtml) {
          marker.addListener("click", async () => {
            this.infoWindow.setContent(markerData.infoHtml);
            this.infoWindow.open(this.map, marker);
          });
        }
      });
    }

    this.infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });

    if (this.fetchCombinedDataValue) {
      await this.fetchMapData();

      this.createMarkers();
    }
  }

  createMarkers(regenerateListings = true) {
    if (regenerateListings) {
      this.listingMarkers = this.listingsData.map(listing => this.createMarker(listing, this.listingHtmlMarker, "listings"));
      this.listingClusterer = new MarkerClusterer({ markers: this.listingMarkers, map: this.map, renderer: this.createCustomRenderer("#0000ff") });
    }

    this.schoolMarkers = this.schoolsData.map(school => this.createMarker(school, this.schoolHtmlMarker, "schools"));
    this.schoolClusterer = new MarkerClusterer({ markers: this.schoolMarkers, map: this.map, renderer: this.createCustomRenderer("#ff0000") });
  }

  createMarker(data, htmlMarker, type) {
    /* const pinGlyph = new google.maps.marker.PinElement({
      glyph: data.name,
      glyphColor: "white", // Assuming you add a type field
    }); */
  
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: { lat: data.lat, lng: data.lng },
      content: htmlMarker.cloneNode(true),
      // content: pinGlyph.element,
    });
  
    marker.addListener("click", async () => {
      if (!data.detailsLoaded) {
        try {
          const response = await fetch(`/${window.currentLocale}/${type}/${data.id}/map_details`);
          const details = await response.json();
          data.html_content = details.html_content; // Save details to avoid refetching
          data.detailsLoaded = true; // Flag to indicate details are loaded
        } catch (error) {
          console.error("Error fetching map details:", error);
        }
      }
      this.infoWindow.setContent(data.html_content);
      this.infoWindow.open(this.map, marker);
    });
  
    return marker;
  }

  async fetchMapData(level, categoryLight, religion) {
    const url = new URL(`${window.location.origin}/${window.currentLocale}/combined_map_data`);
    if (level) url.searchParams.append('level', level);
    if (categoryLight) url.searchParams.append('category_light', categoryLight);
    if (religion) url.searchParams.append('religion', religion);

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch schools');
      const data = await response.json();
      this.listingsData = data.listings;
      this.listingHtmlMarker = this.createDomNodeFromHtml(data.listingHtmlMarker);
      this.schoolsData = data.schools;
      this.schoolHtmlMarker = this.createDomNodeFromHtml(data.schoolHtmlMarker);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async fetchMarkersData() {
    if (!this.markersUrlValue) {
      return null;
    }

    try {
      const response = await fetch(this.markersUrlValue);
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
        console.error("Failed to fetch markers data:", error);
        // Handle errors or rethrow them depending on your application structure
        return null; // Or you might want to return a default value or rethrow the error
    }
  }
  

  async filterSchools() {
    const level = this.levelFilterTarget.value;
    const categoryLight = this.categoryLightFilterTarget.value;
    const religion = this.religionFilterTarget.value;

    this.clearSchoolMarkers();

    // Await all fetch tasks
    await this.fetchMapData(level, categoryLight, religion);

    this.createMarkers(false);
  }

  clearSchoolMarkers() {
    this.schoolMarkers.forEach(marker => marker.map = null);
    this.schoolMarkers = []; // Clear the array of references

    if (this.schoolClusterer) {
      this.schoolClusterer.clearMarkers();
    }
  }

  createDomNodeFromHtml(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim(); // Make sure the HTML is trimmed to avoid unwanted text nodes
    return div.firstChild; // Return the first child element of the created div
  }

  createCustomRenderer(color) {
    return {
      render({ count, position }) {
        // create svg url with fill color
        const svg = window.btoa(`
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity=".6" r="70" />
          <circle cx="120" cy="120" opacity=".3" r="90" />
          <circle cx="120" cy="120" opacity=".2" r="110" />
          <circle cx="120" cy="120" opacity=".1" r="130" />
        </svg>`);

        // create marker using svg icon
        return new google.maps.Marker({
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.maps.Size(45, 45),
          },
          label: {
            text: String(count),
            color: "rgba(255,255,255,0.9)",
            fontSize: "12px",
          },
          // adjust zIndex to be above other markers
          zIndex: 1000 + count,
        });
      }
    };
  }
}
