// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap;

import { t } from './i18n';



Turbo.setConfirmMethod(async (message) => {
  const { default: Swal } = await import('sweetalert2');

  const result = await Swal.fire({
    title: t("are_you_sure"),
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t("yes"),
    cancelButtonText: t("no"),
    reverseButtons: true
  });

  return result.isConfirmed;
});

import "./channels"
import "./public.js"
import "./form.js"
import "./listings_form.js"
import "./auth.js"
import "./district_selection.js"
import "./filter_dropdowns.js"