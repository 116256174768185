import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="photo-gallery"
export default class extends Controller {
  static targets = ["nav", "tabPane"];

  connect() {
    this.currentIndex = 0;
    this.navItems = this.navTarget.querySelectorAll('.nav-item');
    this.totalPhotos = this.navItems.length;
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateActivePhoto(this.currentIndex);
    }
  }

  next() {
    if (this.currentIndex < this.totalPhotos - 1) {
      this.currentIndex++;
      this.updateActivePhoto(this.currentIndex);
    }
  }

  select(event) {
    const index = parseInt(event.currentTarget.dataset.photoGalleryIndex);
    if (index !== this.currentIndex) {
      this.currentIndex = index;
      this.updateActivePhoto(this.currentIndex);
    }
  }

  updateActivePhoto(index) {
    // Update active photo
    this.tabPaneTargets.forEach((el, idx) => {
      el.classList.remove('show', 'active');
      if (idx === index) {
        el.classList.add('show', 'active');
      }
    });

    // Update active thumbnail
    this.navItems.forEach((el, idx) => {
      el.classList.remove('active');
      if (idx === index) {
        el.classList.add('active');
      }
    });

    const itemOffsetLeft = this.navItems[index].getBoundingClientRect().left;
    const containerOffsetLeft = this.navTarget.getBoundingClientRect().left;

    // Calculate the new scroll position
    let newScrollPosition = itemOffsetLeft - containerOffsetLeft;

    // Adjust for current scroll position
    newScrollPosition += this.navTarget.scrollLeft;

    // Animate to the new scroll position
    this.navTarget.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  }
}