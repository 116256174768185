import { Controller } from "@hotwired/stimulus"
import { loadCss } from "../utils/css_loader";

// Connects to data-controller="phone-number"
export default class extends Controller {
  static targets = ["phone"]

  async connect() {
    await loadCss('/intl_tel_input/intlTelInput.css');
    const { default: intlTelInput } = await import('intl-tel-input');

    this.phoneInput = intlTelInput(this.phoneTarget, {
      initialCountry: "HK", // You can set it to a specific country or auto-detect
      strictMode: true,
      allowDropdown: false,
      // preferredCountries: ["HK", "CN"], // List of preferred countries (Hong Kong, China)
      onlyCountries: ["HK"],
      utilsScript: "/intl_tel_input/utils.js" // Required for validation/formatting
    });
  }

  disconnect() {
    // Cleanup if needed
    if (this.phoneInput) {
      this.phoneInput.destroy();
    }
  }

  validate() {
    this.phoneTarget.classList.toggle('number-valid', this.phoneInput.isValidNumber());

    const event = new Event('input', {
      bubbles: true,
      cancelable: true
    });
    this.phoneTarget.dispatchEvent(event);
  }
}
