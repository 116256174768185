import { fetchWithTurboStream } from "./fetchUtils";

// Utility to close all open modals
export function closeModal(id = 'universal-modal') {
  const modalElement = document.getElementById(id);
  const modalInstance = bootstrap.Modal.getInstance(modalElement);
  if (modalInstance) {
    modalInstance.hide();
  }
}

// Utility to open a specific modal by ID
export function openModal(id = 'universal-modal') {
  closeModal('location-modal');

  const modalElement = document.getElementById(id);

  if (modalElement) {
    const modalType = modalElement.getAttribute('data-modal-type');

    const modalCandidates = ['log_in', 'sign_up']

    if (modalCandidates.includes(modalType)) {
      setupModalPositionAndArrow('log-in-button', modalElement, modalType);
    } else {
      resetModalAndArrowStyles(modalElement);
    }

    adjustModalElement(modalElement, modalType);

    if (!modalElement.classList.contains('show')) {
      const modalInstance = new bootstrap.Modal(modalElement, {
        focus: true // Automatically focus the modal when opened
      });
      modalInstance.show();
    }
  }
}

export function updateModalType(id, modalType) {
  const modal = document.getElementById(id);
  if (modal) {
    modal.setAttribute('data-modal-type', modalType);
  }
}

export async function fetchAndDisplayModal(modalType, params = {}) {
  const url = new URL(`/${window.currentLocale}/show_modal/${modalType}`, window.location.origin);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

  await fetchWithTurboStream(url.toString(), { method: "GET" });
}

function setupModalPositionAndArrow(buttonId, modalElement, modalType) {
  const triggerButton = document.getElementById(buttonId);
  if (triggerButton) {
    const rect = triggerButton.getBoundingClientRect();

    const isInViewport = (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );

    if (isInViewport) {
      const modalWidth = window.innerWidth; // Consider adjusting this if modal width is fixed
      const left = rect.left + rect.width / 2 - modalWidth / 2 - 155; // Adjust modal positioning
      const top = rect.bottom - 2; // Adjust vertical positioning

      // Set modal styles
      modalElement.style.position = 'fixed';
      modalElement.style.top = `${top}px`;
      modalElement.style.left = `${left}px`;
      setupArrowPosition(modalElement, modalType, true);
    } else {
      resetModalAndArrowStyles(modalElement);
    }
  } else {
    resetModalAndArrowStyles(modalElement);
  }
}

function setupArrowPosition(modalElement, modalType, visible) {
  const modalArrow = modalElement.querySelector('.modal-arrow');
  if (visible) {
    modalArrow.style.top = '-16px';

    if (modalType === 'log_in') {
      modalArrow.style.right = '80px';
    } else {
      modalArrow.style.right = '275px';
    }

    modalArrow.classList.remove('d-none');
  } else {
    modalArrow.classList.add('d-none');
  }
}

function resetModalAndArrowStyles(modalElement) {
  modalElement.style.position = '';
  modalElement.style.top = '';
  modalElement.style.left = '';
  setupArrowPosition(modalElement, '', false); // Hide the arrow
}

function adjustModalElement(modalElement, modalType) {
  const modalDialog = modalElement.querySelector('.modal-dialog');
  modalDialog.classList.toggle('modal-fullscreen', ['location_mobile', 'filters_mobile', 'pdf_viewer', 'photo_gallery'].includes(modalType));
  modalDialog.classList.toggle('modal-fullscreen-md-down', ['assisted_or_self_listing', 'new_appointment'].includes(modalType));
  modalDialog.classList.toggle('modal-xl', ['photo_session_upload'].includes(modalType));

  const modalHeader = modalElement.querySelector('.modal-header');

  if (['location_mobile', 'filters_mobile'].includes(modalType)) {
    modalHeader.classList.replace('justify-content-center', 'justify-content-start');
  }

  const modalBody = modalElement.querySelector('.modal-body');
  modalBody.classList.toggle('px-4', !['photo_gallery'].includes(modalType));
  modalBody.classList.toggle('px-0', ['photo_gallery'].includes(modalType));
}