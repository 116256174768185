import { Controller } from "@hotwired/stimulus"
import { createDistrictListItem, createEstateListItem } from "../utils/autocomplete";

// Connects to data-controller="autocomplete"
export default class extends Controller {
  static targets = ["input", "suggestions", "districtHeading", "estateHeading", "submitButton", "estateId", "districtId"];

  connect() {
    this.selectedSuggestionIndex = -1;
  }

  handleFocus() {
    this.search();
  }

  handleInput() {    
    this.search();
  }

  search() {
    this.submitButtonTarget.querySelector('span').classList.remove('d-none');
    this.submitButtonTarget.classList.add('no-transition');
    this.submitButtonTarget.classList.add('expanded');
    
    let query = this.inputTarget.value;

    if (!query.length) {
      this.clearSuggestions();
      return;
    }

    fetch(`/${window.currentLocale}/data/autocomplete?query=${encodeURIComponent(query)}`)
      .then(response => response.json())
      .then(data => this.displaySuggestions(data));
  }

  displaySuggestions(data) {
    this.clearSuggestions();
    this.suggestionsTarget.classList.remove('d-none');

    if (data.districts.length > 0) {
      this.districtHeadingTarget.classList.remove('d-none');
      data.districts.forEach((item) => {
        let listItem = createDistrictListItem(item, true);
        listItem.addEventListener('click', () => {
          this.clearSuggestions();
          this.inputTarget.value = item.name;
          this.districtIdTarget.value = item.id;
          this.estateIdTarget.value = "";
        });
        this.suggestionsTarget.insertBefore(listItem, this.estateHeadingTarget);
      });
    } else {
      this.districtHeadingTarget.classList.add('d-none');
    }
    if (data.estates.length > 0) {
      this.estateHeadingTarget.classList.remove('d-none');

      data.estates.forEach((item) => {
        let listItem = createEstateListItem(item, true);
        listItem.addEventListener('click', () => {
          this.clearSuggestions();
          this.inputTarget.value = item.name;
          this.districtIdTarget.value = "";
          this.estateIdTarget.value = item.id;
        });
        this.suggestionsTarget.appendChild(listItem);
      });
    } else {
      this.estateHeadingTarget.classList.add('d-none');
    }
  }

  clearSuggestions() {
    const suggestionItems = this.suggestionsTarget.querySelectorAll('button');
    suggestionItems.forEach(item => item.remove());
    this.suggestionsTarget.classList.add('d-none');
  }

  navigate(e) {
    let suggestions = this.suggestionsTarget.querySelectorAll('button');
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex + 1) % suggestions.length;
        this.highlightSuggestion(suggestions);
        break;
      
      case 'ArrowUp':
        if (this.selectedSuggestionIndex <= 0) this.selectedSuggestionIndex = suggestions.length;
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex - 1) % suggestions.length;
        this.highlightSuggestion(suggestions);
        break;
      
      case 'Enter':
        if (this.selectedSuggestionIndex > -1) {
          e.preventDefault(); // Prevent form submission
          suggestions[this.selectedSuggestionIndex].click();
        }
        break;
    }
  }

  highlightSuggestion(suggestions) {
    suggestions.forEach((suggestion, index) => {
      suggestion.classList.toggle('bg-body-tertiary', index === this.selectedSuggestionIndex);
    });
  }

  hideSuggestions() {
    this.submitButtonTarget.querySelector('span').classList.add('d-none');
    this.submitButtonTarget.classList.remove('no-transition');
    this.submitButtonTarget.classList.remove('expanded');

    setTimeout(() => {
      this.clearSuggestions();
    }, 150);
  }
}
