import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["button"]

  updateText(event) {
    const text = event.target.innerText.trim();
    this.buttonTarget.innerText = text;
  }
}
