import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-password"
export default class extends Controller {
  static targets = ["passwordField", "icon"]

  toggle() {
    // Toggle the type of the password field
    this.passwordFieldTarget.type = this.passwordFieldTarget.type === 'password' ? 'text' : 'password';

    // Toggle the SVG icons
    this.iconTargets.forEach(icon => {
      icon.classList.toggle('d-none');
    });
  }
}
